import React from 'react';
import Player from '../Player/Player';
import './PokerTable.css';

const PokerTable = ({ players, currentPlayer, cards, pot, isPracticeMode }) => {
    const playerPositions = [
        'bottom',
        'bottom-left',
        'left',
        'right',
        'bottom-right',
    ];

    return (
        <div
            className="poker-table"
            style={{ color: 'lightgrey', position: 'relative' }}
        >
            <div className="players">
                {players.map((player, index) => (
                    <Player
                        key={index}
                        name={player.name}
                        position={playerPositions[index]}
                        active={player.active}
                        cards={player.hand.map((card) => [
                            card.rank,
                            card.suit[0],
                        ])}
                        isMyPlayer={player.name === players[0].name}
                        isCurrent={player.name === currentPlayer}
                        lastMoveAmount={player.lastMoveAmount || 0}
                        lastMove={player.lastMove || ''}
                        balance={player.balance}
                        bet={player.bet || 0}
                        isSmallBlind={player.is_small_blind}
                        isBigBlind={player.is_big_blind}
                        winningChance={player.win_chance}
                        isPracticeMode={isPracticeMode}
                    />
                ))}
            </div>
            <div className="pot-size">Pot: {pot} chips</div>
            <div className="cards-container">
                {cards.map((card, index) => (
                    <div key={index}>{card}</div>
                ))}
            </div>
        </div>
    );
};

export default PokerTable;
