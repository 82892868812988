import React, { useState, useRef, useCallback, useEffect } from 'react';
import './styles/App.css';
import PokerTable from './components/PokerTable/PokerTable';
import Card from './components/Card/Card';
import background from './assets/background.jpg';
import afterBackground from './assets/after-background.jpg';
import welcomeImage from './assets/welcome-image.jpg';
import welcome from './assets/welcome.mp4';
import secondVideo from './assets/second-video.mp4';
import transition1 from './assets/transition1.mp4';
import transition2 from './assets/transition2.mp4';
import { motion, AnimatePresence } from 'framer-motion';
import RulesPopup from './components/RulesPopup';
import RaiseSlider from './components/RaiseSlider/RaiseSlider';

const WEBSOCKET_URL = 'wss://ws.poker5.xyz';

function App() {
    const [startScreenVisible, setStartScreenVisible] = useState(true);
    const [numPlayers, setNumPlayers] = useState(2); // state to manage player number

    // Constant for background images
    const [backgroundImage, setBackgroundImage] = useState(background);

    // For button visibility after pressing play
    const [startScreenButtonsVisible, setStartScreenButtonsVisible] =
        useState(true);

    // State for Practice Mode toggle
    const [isPracticeMode, setIsPracticeMode] = useState(false);

    // For what video is playing
    const [videoPlaying, setVideoPlaying] = useState(false);
    // For video transition
    const [tranPlaying, setTranVPlaying] = useState(false);

    // Current maximum bet during each stage of the game
    const [currentBet, setCurrentBet] = useState(0);
    const currentBetRef = useRef(currentBet);
    useEffect(() => {
        currentBetRef.current = currentBet;
    }, [currentBet]);

    // Raise option popup toggle
    const [raiseOptionsVisible, setRaiseOptionsVisible] = useState(false);

    //for message after winning
    const [winPopupVisible, setWinPopupVisible] = useState(false);
    const [winner, setWinner] = useState(null);

    const socket = useRef(null);
    // for the delayed transition
    const timeoutRef = useRef(null);

    const [players, setPlayers] = useState([]);
    const [communityCards, setCommunityCards] = useState([]);

    // Total pot of chips during poker round
    const [pot, setPot] = useState(0);
    const potRef = useRef(pot);
    useEffect(() => {
        potRef.current = pot;
    }, [pot]);

    // Used to track minimum raise
    const [lastRaise, setLastRaise] = useState(0);
    const lastRaiseRef = useRef(lastRaise);
    useEffect(() => {
        lastRaiseRef.current = lastRaise;
    }, [lastRaise]);

    const [currentPlayer, setCurrentPlayer] = useState(null); // Track the current player
    const [wasRaised, setWasRaised] = useState(false); // track if there was a raise during the round

    const [playerName, setPlayerName] = useState("");
    let nameToUse = "You"; //if the player does not input a name, you will be used

    // Function to handle WebSocket messages
    const handleGameStateUpdate = (data) => {
        console.log('Received game state update:', data);
        setPot(data.pot);
        console.log('setting current bet ', data.current_bet);
        setCurrentBet(data.current_bet);
        setPlayers(data.players);
        setCommunityCards(data.community_cards);
        setLastRaise(data.last_raise);
    };

    // Initialize WebSocket connection
    const initializeWebSocket = () => {
        socket.current = new WebSocket(WEBSOCKET_URL);

        const connectionTimeout = setTimeout(() => {
            if (socket.current?.readyState !== WebSocket.OPEN) {
                console.error('WebSocket connection timeout');
                alert('Failed to connect to the server.');
            }
        }, 5000);

        socket.current.onopen = () => {
            clearTimeout(connectionTimeout);
            const registerPacket = {
                type: 'REGISTER',
                data: { name: nameToUse, player_count: numPlayers },
            };
            socket.current.send(JSON.stringify(registerPacket));
        };

        socket.current.onmessage = (event) => {
            try {
                const message = JSON.parse(event.data);
                console.log('Received message:', message);
                switch (message.type) {
                    case 'SEND_STATE':
                        handleGameStateUpdate(message.data);
                        break;

                    case 'SEND_CURRENT_PLAYER':
                        setCurrentPlayer(message.data.player);
                        break;

                    case 'SEND_ROUND_WIN':
                        handleWinMessage(message.data);
                        break;

                    case 'SEND_MOVE': {
                        const {
                            player,
                            move,
                            amount,
                            currentBet: newCurrentBet,
                            lastRaise: newLastRaise,
                        } = message.data;

                        handlePlayerBet(player, move, amount, newCurrentBet);
                        setLastRaise(newLastRaise);
                        break;
                    }

                    case 'SEND_NEW_ROUND':
                        setWasRaised(message.data.name === 'pre-flop');
                        break;

                    default:
                        console.warn(`Unhandled message type: ${message.type}`);
                        break;
                }
            } catch (error) {
                console.log(
                    'Non-JSON or malformed message received:',
                    event.data
                );
            }
        };

        const handleWinMessage = (message) => {
            setWinner(message);
            setWinPopupVisible(true);
        };

        socket.current.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        socket.current.onclose = () => {
            console.log('WebSocket connection closed');
        };
    };
    //Update a specific players last move and bet amount
    const handlePlayerBet = (playerName, move, amount, newCurrentBet) => {
        setPlayers((players) =>
            players.map((player) => {
                if (player.name === playerName) {
                    let updatedPlayer = { ...player };

                    let originalPlayerBet = player.bet;
                    if (originalPlayerBet == null) originalPlayerBet = 0;
                    console.log(
                        `[${player.name}] newCurrentBet: ${newCurrentBet}, originalPlayerbet: ${originalPlayerBet}, move: ${move}, amount: ${amount}, pot: ${potRef.current}`
                    );

                    let newPot = potRef.current;

                    switch (move) {
                        case 'call':
                            const callAmount = newCurrentBet - originalPlayerBet;
                            if (updatedPlayer.balance < callAmount) {
                                updatedPlayer.bet = updatedPlayer.balance;
                                updatedPlayer.balance = 0; // Player is all-in
                            } else {
                                updatedPlayer.balance -= callAmount;
                                updatedPlayer.bet = newCurrentBet;
                            }
    
                            newPot += updatedPlayer.bet;
                            setPot(newPot);
                            break;
    
                        
                        case 'raise':
                            setWasRaised(true);
                            newPot += amount;
                            setPot(newPot);
                            updatedPlayer.bet = originalPlayerBet + amount;
                            updatedPlayer.balance -= amount;

                            break;
                        case 'fold':
                            updatedPlayer.active = false;
                            break;
                        default:
                            break;
                    }

                    updatedPlayer.lastMove = move;
                    updatedPlayer.lastMoveAmount = amount;

                    return updatedPlayer;
                }

                return player;
            })
        );
        setCurrentBet(newCurrentBet);
    };
    // Unified action handler for WebSocket actions
    const handleAction = useCallback((actionType, amount = null) => {
        const packet = {
            type: actionType.toUpperCase(),
            data: { move: actionType, amount },
        };
        console.log('Sending action packet:', packet);
        if (socket.current?.readyState === WebSocket.OPEN) {
            socket.current.send(JSON.stringify(packet));
        } else {
            console.error('WebSocket is not open');
        }
    }, []);

    // Handle what happens after clicking play
    const handlePlayClick = () => {
        nameToUse = playerName.trim() === "" ? "You" : playerName.trim();
        setVideoPlaying(true);
        setStartScreenButtonsVisible(false);
        setTranVPlaying(true);
        timeoutRef.current = setTimeout(() => {
            initializeWebSocket();
            setStartScreenVisible(false);
            setBackgroundImage(afterBackground);
            setVideoPlaying(false);
            setTranVPlaying(true);
        }, 3000);
    };

    const handlePlayerCountChange = (count) => {
        setNumPlayers(count);
    };

    const handleNameChange = (event) => {
        setPlayerName(event.target.value);
    };

    //Function for the Call button
    const handleCallClick = () => {
        let callAmount = currentBetRef.current - players[0].bet;

        const updatedPlayers = players.map((player, index) => {
            if (index === 0) {
                if (player.balance < callAmount) {
                    // Player goes all-in, bet the entire balance
                    player.bet = player.balance + player.bet;
                    player.balance = 0; // All-in, no balance left
                } else {
                    // Player can call the full amount
                    player.bet = currentBetRef.current;
                    player.balance -= callAmount; // Decrease balance by call amount
                }
            }
            return player; // Return other players unchanged
        });
        setPlayers(updatedPlayers);
        handleAction('call');
    };

    //Function for the fold button
    const handleFoldClick = () => {
        handleAction('fold');

        const updatedPlayers = players.map((player, index) => {
            if (index === 0) {
                return { ...player, active: false }; // Mark the main player as folded
            }
            return player; // Return other players unchanged
        });

        setPlayers(updatedPlayers);
        console.log('Folded. Updated players:', updatedPlayers);
    };

    const handleCheckClick = () => {
        handleAction('check');
        console.log('Checked');
    };

    const handleRaiseClick = (amount) => {
        if (amount) {
            handleAction('raise', players[0].bet + amount);
            setPot((prevPot) => prevPot + amount);
            const updatedPlayers = players.map((player, index) => {
                if (index === 0) {
                    return {
                        ...player,
                        bet: player.bet + amount,
                        balance: player.balance - amount,
                    };
                }
                return player;
            });

            setPlayers(updatedPlayers);
        }
        setRaiseOptionsVisible(false);
    };
    // cleans up the delay effect
    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    //toggle change
    const handlePracticeModeChange = () => {
        setIsPracticeMode(!isPracticeMode);
    };

    return (
        <div
            className="App"
            style={{ backgroundImage: `url(${backgroundImage})` }}
        >
            <div className="rules-popup">
                <RulesPopup />
            </div>

            {startScreenButtonsVisible && (
                <div className="practice-mode">
                    <div className="toggle-wrapper">
                        <label className="practice-mode-label">
                        Practice Mode:
                        </label>
                        <div
                            className={`toggle-container ${isPracticeMode ? 'active' : ''}`}
                            onClick={handlePracticeModeChange} 
                            >
                            <span className={`slider ${isPracticeMode ? 'active' : ''}`}></span>
                        </div>
                    </div>
                </div>
            )}

            {/* Animate the start screen */}
            <AnimatePresence>
                {startScreenVisible && (
                    <motion.div
                        className="start-screen fast-spin"
                        style={{
                            backgroundImage: `url(${welcomeImage})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                        initial={{ opacity: 0, height: '80vh' }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0, y: -100 }}
                        transition={{ duration: 2 }}
                    >
                        {!videoPlaying && (
                            <video
                                src={welcome}
                                autoPlay
                                loop
                                muted
                                playsInline
                              className="background-video"
                            />
                        )}
                        {videoPlaying && (
                            <video
                                src={secondVideo}
                                autoPlay
                                muted
                                playsInline
                                className="background-video"
                            />
                        )}
                        <motion.div
                            initial={{ opacity: 0, y: -50 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 1 }}
                            className="transition-container"
                        >
                            <video
                                src={transition2}
                                autoPlay
                                loop
                                muted
                                playsInline
                               className="transition-video"
                            />
                            {tranPlaying && (
                                <video
                                    src={transition1}
                                    autoPlay
                                    muted
                                    playsInline
                                    className="transition-video-alt"
                                />
                            )}
                        </motion.div>

                        {startScreenButtonsVisible && (
                            <div className="player-selection">
                                {[2, 3, 4, 5].map((playerCount) => (
                                    <button
                                        key={playerCount}
                                        className={`player-selection-button ${
                                            numPlayers === playerCount ? 'selected' : ''
                                        }`}
                                        onClick={() => handlePlayerCountChange(playerCount)}
                                    >
                                        {playerCount} Players
                                    </button>
                                ))}
                            </div>
                        )}
                        
                        {startScreenButtonsVisible && (
                            <div className="player-registration">
                                <input
                                    type="text"
                                    value={playerName}
                                    onChange={handleNameChange}
                                    placeholder="Enter your name"
                                    className="name-input"
                                />
                            </div>
                        )}

                        {startScreenButtonsVisible && (
                            <motion.button
                                className="play-button"
                                onClick={handlePlayClick}
                                transition={{ duration: 0.2 }}
                            >
                                Play
                            </motion.button>
                        )}
                    </motion.div>
                )}
            </AnimatePresence>

            {!startScreenVisible && (
                <motion.div
                    className="poker-table"
                    data-testid="poker-table"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                >
                    <PokerTable
                        players={players} // Players & cards will be dynamically updated on state update from server
                        currentPlayer={currentPlayer}
                        cards={communityCards.map((card) => (
                            <Card value={card.rank} suit={card.suit[0]} />
                        ))}
                        pot={pot}
                        isPracticeMode={isPracticeMode}
                    />
                    {players[0]?.active && (
                        <>
                            {!raiseOptionsVisible && (
                                <div className="action-button-container">
                                    {/* Conditionally render "Call" or "Check" button */}
                                    {wasRaised && players[0]?.balance > 0 ? (
                                        <button
                                            className="action-button"
                                            onClick={handleCallClick}
                                             
                                        >
                                            Call
                                        </button>
                                    ) : !wasRaised ? (
                                        <button
                                            className="action-button"
                                            onClick={handleCheckClick}
                                            data-testid="check-button"
                                        >
                                            Check
                                        </button>
                                    ): null}

                                    {/* Fold Button */}
                                    <button
                                        className="action-button"
                                        onClick={handleFoldClick}
                                        data-testid="fold-button"
                                    >
                                        Fold
                                    </button>

                                    {/* Raise Button */}
                                    {!raiseOptionsVisible && players[0]?.balance > (currentBet - players[0]?.bet + lastRaise) && (
                                        <button
                                            className="action-button"
                                            onClick={() =>
                                                setRaiseOptionsVisible(true)
                                            }
                                        >
                                            Raise
                                        </button>
                                    )}
                                </div>
                            )}

                            {/* Raise Options Popup */}
                            {/* Call amount = Current bet - players[0].bet */}
                            {/* Min raise amount = Call amount + Last raise = Current bet - players[0].bet + Last raise*/}
                            {raiseOptionsVisible && (
                                <RaiseSlider
                                    minBet={
                                        (currentBet - players[0].bet + lastRaise) > players[0].balance
                                            ? players[0].balance
                                            : currentBet - players[0].bet + lastRaise
                                    }
                                    maxBet={players[0].balance}
                                    balance={players[0].balance}
                                    onConfirm={(amount) =>
                                        handleRaiseClick(amount)
                                    }
                                />
                            )}
                        </>
                    )}
                </motion.div>
            )}

            {winPopupVisible && (
                <div className="win-popup">
                    <h2>
                        {winner.player} won {winner.amount} chips
                    </h2>
                    <button onClick={() => setWinPopupVisible(false)}>
                        Close
                    </button>
                </div>
            )}
        </div>
    );
}

export default App;
