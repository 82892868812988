import React from 'react';
import './Player.css';
import Card from '../Card/Card';
import ChipStack from '../ChipStack/ChipStack';
import chipBalanceImage from '../../assets/chips/chip-balance.svg';
import profilePictureImage from '../../assets/profile-picture.png';

const Player = ({
    name,
    position,
    cards,
    active,
    isCurrent,
    lastMoveAmount,
    lastMove,
    balance,
    bet,
    isSmallBlind,
    isBigBlind,
    isMyPlayer,
    winningChance,
    isPracticeMode,
}) => {
    return (
        <div
            className={`player ${position} 
            ${isCurrent ? 'current' : ''} 
            ${!active ? 'folded' : ''}`}
            data-testid="player"
        >
            {/* Winning chance */}
            {isPracticeMode && (
                <div className="player-winning-chance">
                    Win: {winningChance}
                </div>
            )}
            {/* Cards on top */}
            <div className="player-cards">
                {cards.map((card, index) => (
                    <Card
                        key={index}
                        value={card[0]}
                        suit={card[1]}
                        isFaceDown={!isPracticeMode && !isMyPlayer}
                    />
                ))}
            </div>
            {/* Player chips */}
            {bet > 0 && (
                <div className={`player-chips ${position}`}>
                    <ChipStack amount={bet} />
                </div>
            )}

            {/* Player info and image */}
            <div className="player-info-container">
                <div className="player-image">
                    <img src={profilePictureImage} alt="Player" />
                </div>
                <div className="player-details">
                    <div className="player-name">{name}</div>
                    {/*Small blind token*/}
                    {isSmallBlind && (
                        <div className={`blind-token small token-${position}`}>
                            SB
                        </div>
                    )}
                    {/*Big blind token*/}
                    {isBigBlind && (
                        <div className={`blind-token big token-${position}`}>
                            BB
                        </div>
                    )}
                    <div className="player-balance">
                        <img
                            src={chipBalanceImage}
                            alt="Chips"
                            className="chips-icon"
                        />
                        {balance}
                    </div>
                </div>
            </div>

            {/* Player action */}
            <div
                className="player-action"
                style={{ visibility: lastMove ? 'visible' : 'hidden' }}
            >
                {lastMove && (
                    <>
                        {lastMove.toUpperCase()}
                        {lastMoveAmount > 0 ? ` ${lastMoveAmount}` : ''}
                    </>
                )}
            </div>
        </div>
    );
};

export default Player;
